import { isNonEmptyArray } from '@sortlist-frontend/utils';
import { ReactNode } from 'react';

import { Expertise } from '_core/repos/expertises.repo';
import { FreeTextOption, Skill } from '_types/generic';

export const defaultStyles = {
  bodyClass: 'py-24',
  titleClass: 'mb-16 bold',
};

export const getFormExpertise = (expertiseId?: number, allExpertises?: Expertise[]) => {
  return allExpertises?.find((expertise) => expertise.expertiseId === expertiseId);
};

export const formatTermsToSearch = (skills: Skill[]) => {
  const skillsUuid = skills.map((skill) => skill.uuid || '').reverse();
  return skillsUuid.filter((uuid) => uuid !== '');
};

export const getTermsToSearch = (skills: Skill[], formExpertise?: Expertise) => {
  if (!formExpertise) return formatTermsToSearch(skills);
  const skillsToSearch = [{ name: formExpertise.name, uuid: formExpertise.uuid }, ...skills];
  return formatTermsToSearch(skillsToSearch);
};

export const getSkillsV2Answer = (value?: Skill[]): ReactNode | undefined => {
  if (!isNonEmptyArray(value)) return undefined;
  return value?.map((skill: Skill, index: number) => {
    return (
      <span key={index} className="code mr-8 mb-8">
        {skill.name}
      </span>
    );
  });
};

export const getFreeTextLabel = (
  picked: FreeTextOption,
  choices: FreeTextOption[],
): FreeTextOption['value'] | undefined => {
  const choice = choices.find(({ tag }) => tag === picked?.tag);
  if (!choice || !picked) return undefined;
  if (choice.tag === 'other') return picked?.value;
  return choice.value;
};
