import styled from '@emotion/styled';
import { Skeleton as MUISkeleton } from '@mui/material';
import { COLORS } from '@sortlist-frontend/design-system';

type SkeletonProps = {
  color?: string;
};

export const Skeleton = styled(MUISkeleton)`
  ${(props: SkeletonProps) => `
    background-color: ${
      props.color ? COLORS[props.color.replace('-', '_').toUpperCase() as keyof typeof COLORS] : COLORS.SECONDARY_300
    };
  `};
`;
