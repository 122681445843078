'use client';

import { cx } from '@emotion/css';
import { InfoTwoTone, WarningTwoTone } from '@mui/icons-material';
import { ChangeEvent, ComponentPropsWithoutRef, forwardRef, ReactElement, useState } from 'react';

import { Tooltip } from '../../Tooltip';
import type { Size } from '../../types';

export type InputProps = {
  className?: string;
  error?: boolean;
  errorIcon?: boolean;
  errorMessage?: string;
  borderColor?: string;
  bgColor?: string;
  icon?: ReactElement;
  innerIcon?: ReactElement;
  innerRightIcon?: ReactElement;
  label?: string;
  stretch?: boolean;
  height?: Size | '';
  tip?: boolean;
  tipIcon?: boolean;
  tipMessage?: string;
  fieldStyle?: 'select' | 'input';
} & Partial<ComponentPropsWithoutRef<'input'>>;

const defaultProps = {
  height: 'xs',
  stretch: true,
};

/* eslint-disable sonarjs/cognitive-complexity */
export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    icon,
    height,
    error,
    label,
    stretch,
    tipIcon,
    bgColor,
    innerIcon,
    innerRightIcon,
    errorIcon,
    className,
    tipMessage,
    borderColor,
    errorMessage,
    onChange,
    ...restProps
  } = {
    ...defaultProps,
    ...props,
  };

  const showTipIcon = !error && !errorIcon && tipMessage && tipIcon;
  const showErrorIcon = error && errorIcon && errorMessage && !restProps.disabled;
  const showErrorMessage = error && !errorIcon && errorMessage && !restProps.disabled;

  const tipClassName = cx({ 'pr-16': !!restProps.maxLength });
  const errorClass = cx({ 'form-error': error });
  const rightSpaceClass = cx({
    'input-space-right': showErrorIcon != null || showTipIcon != null || innerRightIcon != null,
  });
  const leftSpaceClass = cx({ 'input-space-left': innerIcon != null });

  const styles = {
    ...(restProps.disabled ? { border: 'none' } : {}),
    ...(restProps.style ? restProps.style : {}),
  };

  const [counter, setCounter] = useState((restProps.defaultValue as string)?.length || 0);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCounter(e.currentTarget.value.length);
  };

  return (
    <div
      className={cx(
        stretch ? 'layout-align-start-stretch' : 'layout-align-start-start',
        className,
        'layout-column layout-align-start-start',
      )}>
      {label && (
        <label className="medium mb-4 small display-block" htmlFor={restProps.id}>
          {label}
        </label>
      )}

      <div className="field-container layout-row layout-align-start-center">
        {icon && <div className="layout-column layout-align-center-center mr-8">{icon}</div>}
        {innerIcon && <div className={`layout field-icon-left-${height}`}>{innerIcon}</div>}
        <div className={cx(stretch ? 'layout-align-start-stretch' : 'layout-align-start-start', 'layout-column flex')}>
          <input
            {...restProps}
            style={styles}
            // eslint-disable-next-line sonarjs/no-nested-template-literals
            className={`input flex-100 input-${height} ${leftSpaceClass} ${bgColor && `bg-${bgColor}`} ${
              borderColor && `border-${borderColor}`
            } ${errorClass} ${rightSpaceClass}`}
            onChange={(e) => {
              handleChange(e);
              onChange && onChange(e);
            }}
            ref={ref}
          />
          {(showErrorMessage || restProps.maxLength || tipMessage) && (
            <div className="layout-row layout-align-start-start pt-4">
              <div className={`flex layout-row layout-align-start-start ${tipClassName}`}>
                {showErrorMessage && <p className="small text-error">{errorMessage}</p>}
                {tipMessage && !error && <p className="small">{tipMessage}</p>}
              </div>
              {restProps.maxLength && (
                <div>
                  <small>
                    {counter}/{restProps.maxLength}
                  </small>
                </div>
              )}
            </div>
          )}
        </div>

        {innerRightIcon && <div className={`layout field-icon-right-${height} layout p-8`}>{innerRightIcon}</div>}

        {showErrorIcon && errorMessage && (
          <div className={`field-icon-right-${height} layout p-8`}>
            <Tooltip arrow error title={errorMessage}>
              <WarningTwoTone style={{ fontSize: 16 }} className="text-danger-500" />
            </Tooltip>
          </div>
        )}

        {showTipIcon && tipMessage && (
          <div className={`field-icon-right-${height} layout p-8`}>
            <Tooltip arrow title={tipMessage}>
              <InfoTwoTone style={{ fontSize: 16 }} className="text-secondary-900" />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
});
