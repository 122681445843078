import { cx } from '@emotion/css';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { ChangeEvent, FC, InputHTMLAttributes, useEffect, useState } from 'react';

import type { Size } from '../types';

export type RadioProps = {
  color?: string;
  id: string;
  name: string;
  checked?: boolean;
  iconSize?: Size;
  disabled?: boolean;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
} & Partial<InputHTMLAttributes<HTMLInputElement>>;

const defaultProps = {
  color: 'primary-500',
};

export const Radio: FC<RadioProps> = (props: RadioProps) => {
  const { checked, color, iconSize, id, name, onChange, value, disabled, ...restProps } = { ...defaultProps, ...props };
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    let timeout: number;
    if (shouldAnimate) {
      timeout = window.setTimeout(() => {
        setShouldAnimate(false);
      }, 800);
    }
    return () => {
      if (timeout) window.clearTimeout(timeout);
    };
  }, [shouldAnimate]);

  return (
    <div
      className={cx(
        iconSize ? `radio-${iconSize}` : null,
        checked ? 'radio--selected' : null,
        shouldAnimate ? 'animate-ripple' : null,
        disabled ? 'radio-disabled cursor-not-allowed' : 'cursor-pointer',
        'radio mr-8',
      )}
      {...restProps}>
      <input
        type="radio"
        id={id}
        name={name}
        checked={checked}
        disabled={disabled}
        value={value}
        className={cx(disabled ? 'cursor-not-allowed' : 'cursor-pointer')}
        onChange={(ev) => {
          setShouldAnimate(true);
          onChange(ev);
        }}
      />
      <RadioButtonChecked
        className={cx(
          color ? `text-${color}` : 'text-primary-500',
          disabled ? 'radio-disabled cursor-not-allowed' : 'cursor-pointer',
          'radio__checked',
        )}
      />
      <RadioButtonUnchecked
        className={cx('text-secondary-500', disabled ? 'radio-disabled cursor-not-allowed' : 'cursor-pointer')}
      />
    </div>
  );
};
