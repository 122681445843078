import { cx } from '@emotion/css';
import type { FC, ReactElement, ReactNode } from 'react';

import type { CheckboxProps, RadioProps } from '../index';

type Props = {
  control: ReactElement<CheckboxProps> | ReactElement<RadioProps>;
  label?: string | ReactNode;
  className?: string;
  labelClassName?: string;
};

export const FormControl: FC<Props> = (props: Props) => {
  const { control, label, className, labelClassName } = props;

  return (
    <div className={cx(className, 'layout-row layout-align-start-center')}>
      {control}
      <label className={cx(labelClassName, 'text-left')} htmlFor={control.props.id}>
        {label}
      </label>
    </div>
  );
};
